const functions =  {
    availableMethods() {
        return [
            'index',
            'acknowledge',
            'accept',
            'reject',
            'fill',
            'cancel',
        ]
    },

    acknowledge(id, cancelKey) {
        const url = `${this.modelName}/${id}/acknowledge`

        return this._callApi(url, 'put', [], cancelKey)
    },

    accept(id, cancelKey) {
        const url = `${this.modelName}/${id}/accept`

        return this._callApi(url, 'put', [], cancelKey)
    },

    reject(id, cancelKey) {
        const url = `${this.modelName}/${id}/reject`

        return this._callApi(url, 'put', [], cancelKey)
    },

    fill(id, data, cancelKey) {
        const url = `${this.modelName}/${id}/fill`

        return this._callApi(url, 'put', data, cancelKey)
    },

    cancel(id, cancelKey) {
        const url = `${this.modelName}/${id}/cancel`

        return this._callApi(url, 'put', [], cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
