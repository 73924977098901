const functions =  {
    availableMethods() {
        return [
            'index',
            'create',
            'update',
            'delete',
            'activate',
            'deactivate'
        ]
    },

    activate(badId, cancelKey) {
        const url = `/bads/${badId}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(badId, cancelKey) {
        const url = `/bads/${badId}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
