window._ = require('lodash')
window._tradingAxios = require('axios')
window.TradingEloquent = require('./Eloquent').default
window._TradingDefaultCancelKey = 'GLOBAL_CANCEL_SOURCE'
window._TradingCancelSourceMap = {
    GLOBAL_CANCEL_SOURCE: _tradingAxios.CancelToken.source()
}

/**
 * Declared _BaseAxios, because _axios is modified below,
 * _BaseAxios is need in creating new Cancel Token
 */
window._TradingBaseAxios = require('axios')

const baseUrls = {
    local: 'http://rmd-trading.local',
    production: 'https://colossal-beirut-wnubqz0an82r.vapor-farm-g1.com',
    development: 'https://hollow-reserve-ocy09eqsg19w.vapor-farm-c1.com',
    delivery: 'https://dev-trading-api.paper-trader.com'
}

let settings = {
    //todo change urls
    baseDomain: baseUrls[process.env.NODE_ENV]
}

if (process.env.NODE_ENV !== 'production') {
    if (window._playground) {
        settings = {
            baseDomain: ''
        }
    }
}

_tradingAxios = _tradingAxios.create({
    baseURL: settings.baseDomain,
    withCredentials: true,
})

_tradingAxios.defaults.validateStatus = status => {
    return status >= 200 && status < 500
}

_tradingAxios.interceptors.response.use(response => {
    response.isOk = response.status >= 200 && response.status < 300
    return response
})

const models = {
    ProductTypes: require('./ProductTypes').default,
    Products: require('./Products').default,
    Orders: require('./Orders').default,
    Tenders: require('./Tenders').default,
    Negotiations: require('./Negotiations').default,
    OrderBooks: require('./OrderBooks').default,
    User: require('./User').default,
    Brokerages: require('./Brokerages').default,
    TradingCompanies: require('./TradingCompanies').default,
    BulkOrders: require('./BulkOrders').default,
    BulkIndicativeOrders: require('./BulkIndicativeOrders').default,
    BulkNegotiations: require('./BulkNegotiations').default,
    NegotiationMessage: require('./NegotiationMessage').default,
    Countries: require('./Countries').default,
    Currencies: require('./Currencies').default,
    CompanyRegistration: require('./CompanyRegistration').default,
    Registration: require('./Registration').default,
    Counterparties: require('./Counterparties').default,
    MyCompany: require('./MyCompany').default,
    MyProductTypes: require('./MyProductTypes').default,
    MyCommodityGroups: require('./MyCommodityGroups').default,
    MyTraders: require('./MyTraders').default,
    MyBrokers: require('./MyBrokers').default,
    MyLimits: require('./MyLimits').default,
    MyClients: require('./MyClients').default,
    CounterpartiesProductTypes: require('./CounterpartiesProductTypes').default,
    Companies: require('./Companies').default,
    MarketPrices: require('./MarketPrices').default,
    MiddleOffices: require('./MiddleOffices').default,
    Bads: require('./Bads').default,
    CommodityGroups: require('./CommodityGroups').default,
    PTAdmins: require('./PTAdmins').default,
    Reports: require('./Reports').default,
    Trades: require('./Trades').default,
    DataUsers: require('./DataUsers').default,
    Plans: require('./Plans').default,
    Futures: require('./Futures').default,
    Tenors: require('./Tenors').default,
    TimeZones: require('./TimeZones').default,
    RestartTradingDay: require('./RestartTradingDay').default,
    Watchlists: require('./Watchlists').default,
}

_.each(models, (modelFunctions, modelName) => {
    TradingEloquent[modelName] = TradingEloquent._makeClass(modelName, modelFunctions)
})
