const functions =  {
    availableMethods() {
        return [
            'index',
            'create',
            'readAll',
            'readMany'
        ]
    },

    index(negotiationId, data, cancelKey) {
        const url = `negotiations/${negotiationId}/messages`

        return this._callApi(url, 'get', data, cancelKey)
    },

    create(negotiationId, data, cancelKey) {
        const url = `negotiations/${negotiationId}/messages`

        return this._callApi(url, 'post', data, cancelKey)
    },

    readAll(negotiationId, cancelKey) {
        const url = `negotiations/${negotiationId}/messages/read-all`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    readMany(negotiationId, data, cancelKey) {
        const url = `negotiations/${negotiationId}/messages/read-many`

        return this._callApi(url, 'put', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
