const functions =  {
    availableMethods() {
        return [
            'index',
            'activate',
            'deactivate',
            'updateProfile',
            'updateTutorialStep',
            'me',
        ]
    },

    index(data, cancelKey) {
        const url = '/users'

        return this._callApi(url, 'get', data, cancelKey)
    },

    all(data, cancelKey) {
        const url = '/users/all'

        return this._callApi(url, 'get', data, cancelKey)
    },

    activate(userId, cancelKey) {
        const url = `/users/${userId}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(userId, cancelKey) {
        const url = `/users/${userId}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    updateTutorialStep(data, cancelKey) {
        const url = '/update-tutorial-step'
        return this._callApi(url, 'put', data, cancelKey)
    },

    updateProfile(data, cancelKey) {
        const url = '/me'
        return this._callApi(url, 'put', data, cancelKey)
    },

    me(cancelKey) {
        const url = '/me'
        return this._callApi(url, 'get', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
