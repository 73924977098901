const functions =  {
    availableMethods() {
        return [
            "all",
            'index',
            'show',
            'create',
            'update',
            'delete'
        ]
    },

    index(data, cancelKey) {
        const url = `${this.modelName}`

        return this._callApi(url, 'get', data, cancelKey)
    },

    all(data, cancelKey) {
        const url = `${this.modelName}/all`

        return this._callApi(url, 'get', data, cancelKey)
    },

    show(tenor, data, cancelKey) {
        const url = `${this.modelName}/${tenor}`

        return this._callApi(url, 'get', data, cancelKey)
    },

    create(data, cancelKey) {
        const url = `${this.modelName}`

        return this._callApi(url, 'post', data, cancelKey)
    },

    update(tenor, data, cancelKey) {
        const url = `${this.modelName}/${tenor}`

        return this._callApi(url, 'put', data, cancelKey)
    },

    delete(tenor, data, cancelKey) {
        const url = `${this.modelName}/${tenor}`

        return this._callApi(url, 'delete', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
