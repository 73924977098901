const functions =  {
    availableMethods() {
        return [
            'index',
            'show',
            'create',
            'update',
            'accept',
            'cancel',
            'tenors',
            'all',
            'logs',
            'acknowledgeDone',
            'nudge'
        ]
    },

    logs(id, cancelKey) {
        const url = `${this.modelName}/${id}/logs`

        return this._callApi(url, 'get', [], cancelKey)
    },

    all(data, cancelKey) {
        const url = `${this.modelName}/all`

        return this._callApi(url, 'get', data, cancelKey)
    },

    accept(id, data, cancelKey) {
        const url = `${this.modelName}/${id}/accept`

        return this._callApi(url, 'put', data, cancelKey)
    },

    cancel(id, data, cancelKey) {
        const url = `${this.modelName}/${id}/cancel`

        return this._callApi(url, 'put', data, cancelKey)
    },

    acknowledgeDone(id, cancelKey) {
        const url = `${this.modelName}/${id}/acknowledge-done`

        return this._callApi(url, 'put', [], cancelKey)
    },

    tenors(data, cancelKey) {
        const url = `${this.modelName}/tenors`

        return this._callApi(url, 'get', data, cancelKey)
    },

    nudge(id, data, cancelKey) {
        const url = `${this.modelName}/${id}/nudge`

        return this._callApi(url, 'put', data, cancelKey)
    },

    acknowledgeNudge(id, data, cancelKey) {
        const url = `${this.modelName}/${id}/acknowledge-nudge`

        return this._callApi(url, 'put', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
