const functions =  {
    availableMethods() {
        return [
            'index',
            'create',
            'update',
            'show',
            'activate',
            'deactivate'
        ]
    },

    activate(traderId, cancelKey) {
        const url = `/my-brokers/${traderId}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(traderId, cancelKey) {
        const url = `/my-brokers/${traderId}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    resendInvitationEmail(brokerId, cancelKey) {
        const url = `/my-brokers/${brokerId}/resend-invitation-email`

        return this._callApi(url, 'post', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
