const functions =  {
    availableMethods() {
        return [
            'store'
        ]
    },
    
    store(data, cancelKey) {
        const url = `register`

        return this._callApi(url, 'post', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
