class BaseModel {

    constructor(attributes, exists = false) {
        this._exists = exists
        _.forEach(attributes, (value, key) => {
            // remove this bracket will cause issue if value = false, it will exit loop.
            this[key] = value
        })
    }

    get modelName() {
        return this.constructor.modelName
    }

    _getAttributes() {
        let attributes = {}

        _.forEach(this, (value, key) => {
            if(! _.startsWith(key, '_')) {
                attributes[key] = value
            }
        })

        return attributes
    }

    _callApi(...agrs) {
        return this.constructor._callApi(...agrs)
    }

    static setModelName(name) {
        this.modelName = name
    }

    static availableMethods() {
        throw new Error('Available Methods are not defined!')
    }

    static index(data, cancelKey) {
        const url = `${this.modelName}`

        return this._callApi(url, 'get', data, cancelKey)
    }

    static show(id, cancelKey) {
        const url = `${this.modelName}/${id}`

        return this._callApi(url, 'get', {}, cancelKey)
    }

    static create(data, cancelKey) {
        const url = `${this.modelName}`

        return this._callApi(url, 'post', data, cancelKey)
    }

    static update(id, data, cancelKey) {
        const url = `${this.modelName}/${id}`

        return this._callApi(url, 'put', data, cancelKey)
    }

    static delete(id, cancelKey) {
        const url = `${this.modelName}/${id}`

        return this._callApi(url, 'delete', {}, cancelKey)
    }

    static _callApi(url, method = 'get', data = {}, cancelKey=_TradingDefaultCancelKey) {
        /**
         * 1. cancelKey defaults to _DefaultCancelKey (see `app.js`)
         * 2. Add cancelKey in map if not yet existing
         */

        let cancelToken;

        if(typeof cancelKey == 'string') {
            if (!_TradingCancelSourceMap[cancelKey]) {
                _TradingCancelSourceMap[cancelKey] = _TradingBaseAxios.CancelToken.source()
                cancelToken = _TradingCancelSourceMap[cancelKey].token
            }
        } else {
            cancelToken = cancelKey;
        }

        return new Promise((resolve, reject) => {
            _tradingAxios.request({
                url : url,
                method,
                data: method !== 'get' ? data : null,
                params: method === 'get' ? data : {},
                cancelToken: cancelToken
            })
                .then(data => {
                    if(data.data instanceof Array) {
                        // collection response
                        if(data.data[0] instanceof Object) {
                            let cloned = []

                            _.forEach(data.data, value => cloned.push(new this(value, true)))

                            data.data = cloned

                            return resolve(data)
                        }

                        // normal array or nothing found
                        return resolve(data)
                    }


                    if(data.data instanceof Object)
                    {
                        // single model response
                        if(data.data.id !== undefined) {
                            data.data = new this(data.data, true)

                            return resolve(data)
                        }

                        // pagination result
                        if(data.data.data instanceof Array) {
                            let cloned = []

                            _.forEach(data.data.data, value => cloned.push(new this(value, true)))

                            data.data.data = cloned

                            return resolve(data)
                        }

                        return resolve(data)
                    }

                    return resolve(data)
                })
                .catch(err => reject(err))
        })
    }
}

export default BaseModel
