const functions =  {
    availableMethods() {
        return [
            'restart',
        ]
    },

    restart(cancelKey) {
        const url = '/restart-trading-day'

        return this._callApi(url, 'post', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
