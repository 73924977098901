const functions =  {
    availableMethods() {
        return [
            'index',
            'all',
        ]
    },

    all(data, cancelKey) {
        const url = `${this.modelName}/all`

        return this._callApi(url, 'get', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
