const functions =  {
    availableMethods() {
        return [
            'index',
            'show',
            'create',
            'update',
            'cancel',
            'childs',
            'logs',
            'nudge',
            'secondaryClients',
            'bookFuture',
            'regenerate'
        ]
    },

    cancel(id, cancelKey) {
        const url = `${this.modelName}/${id}/cancel`

        return this._callApi(url, 'put', [], cancelKey)
    },

    logs(id, cancelKey) {
        const url = `${this.modelName}/${id}/logs`

        return this._callApi(url, 'get', [], cancelKey)
    },

    childs(id, cancelKey) {
        const url = `${this.modelName}/${id}/childs`

        return this._callApi(url, 'get', [], cancelKey)
    },

    nudge(id, data, cancelKey) {
        const url = `${this.modelName}/${id}/nudge`

        return this._callApi(url, 'put', data, cancelKey)
    },

    secondaryClients(id, cancelKey) {
        const url = `${this.modelName}/${id}/secondary-clients`

        return this._callApi(url, 'get', [], cancelKey)
    },

    bookFuture(id, data, cancelKey) {
        const url = `${this.modelName}/${id}/book-future`

        return this._callApi(url, 'post', data, cancelKey)
    },

    regenerate(data, cancelKey) {
        const url = `${this.modelName}/regenerate`

        return this._callApi(url, 'post', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
