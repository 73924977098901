const functions =  {
    availableMethods() {
        return [
            'index',
            'store',
            'myCounters',
            'counters',
            'participate',
            'initiateCounter'
        ]
    },

    store(data, cancelKey) {
        const url = `${this.modelName}`

        return this._callApi(url, 'post', data, cancelKey)
    },

    myCounters(data, cancelKey) {
        const url = `${this.modelName}/my-counters`

        return this._callApi(url, 'get', data, cancelKey)
    },

    counters(orderId, data, cancelKey) {
        const url = `${this.modelName}/${orderId}/counters`

        return this._callApi(url, 'get', data, cancelKey)
    },

    participate(data, cancelKey) {
        const url = `${this.modelName}/participate`

        return this._callApi(url, 'post', data, cancelKey)
    },

    initiateCounter(negoId, data, cancelKey) {
        const url = `${this.modelName}/negotiations/${negoId}/initiate-counter`

        return this._callApi(url, 'post', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
