const functions =  {
    availableMethods() {
        return [
            'index',
            'storeMany',
            'unassigned',
            'activate',
            'deactivate',
            'update'
        ]
    },

    activate(id, cancelKey) {
        const url = `${this.modelName}/${id}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(id, cancelKey) {
        const url = `${this.modelName}/${id}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    storeMany(ids, cancelKey) {
        const url = `${this.modelName}/store-many`

        return this._callApi(url, 'post', {ids: ids}, cancelKey)
    },

    unassigned(data, cancelKey) {
        const url = `${this.modelName}/unassigned`

        return this._callApi(url, 'get', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
