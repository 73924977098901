const functions =  {
    availableMethods() {
        return [
            'index',
            'create',
            'update',
            'delete',
            'activate',
            'deactivate'
        ]
    },

    activate(dataUserId, cancelKey) {
        const url = `/data-users/${dataUserId}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(dataUserId, cancelKey) {
        const url = `/data-users/${dataUserId}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
