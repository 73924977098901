const functions =  {
    availableMethods() {
        return [
            'index',
            'show',
            'create',
            'update',
            'traders',
            'storeMany',
            'approve',
            'activate',
            'deactivate',
            'relatedProductTypes',
            'unassigned',
        ]
    },

    traders(companyId, data, cancelKey) {
        const url = `my-clients/${companyId}/traders`

        return this._callApi(url, 'get', data, cancelKey)
    },

    relatedProductTypes(id, cancelKey) {
        const url = `${this.modelName}/${id}/related-product-types`

        return this._callApi(url, 'get', {}, cancelKey)
    },

    approve(id, cancelKey) {
        const url = `${this.modelName}/${id}/approve`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    activate(id, cancelKey) {
        const url = `${this.modelName}/${id}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(id, cancelKey) {
        const url = `${this.modelName}/${id}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    all(data, cancelKey) {
        const url = `${this.modelName}/all`

        return this._callApi(url, 'get', data, cancelKey)
    },

    unassigned(data, cancelKey) {
        const url = `${this.modelName}/unassigned`

        return this._callApi(url, 'get', data, cancelKey)
    },

    storeMany(ids, cancelKey) {
        const url = `${this.modelName}/store-many`

        return this._callApi(url, 'post', {ids: ids}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
