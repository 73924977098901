const functions =  {
    availableMethods() {
        return [
            'index',
            'updateLimit',
            'regenerateSecret',
            'updateCommodityGroups',
            'updatePlan',
            'update',
            'updateOnboardingStep',
            'submitForReview'
        ]
    },

    index(data, cancelKey) {
        const url = `my-company`

        return this._callApi(url, 'get', data, cancelKey)
    },

    updateOnboardingStep(data, cancelKey) {
        const url = `my-company/onboarding-step`

        return this._callApi(url, 'put', data, cancelKey)
    },

    update(data, cancelKey) {
        const url = `my-company`

        return this._callApi(url, 'put', data, cancelKey)
    },

    submitForReview(cancelKey) {
        const url = `my-company/submit-for-review`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    updateLimit(data, cancelKey) {
        const url = `my-company/update-limit`

        return this._callApi(url, 'put', data, cancelKey)
    },

    regenerateSecret(cancelKey) {
        const url = `my-company/regenerate-secret`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    updateCommodityGroups(data, cancelKey) {
        const url = `my-company/commodity-groups`

        return this._callApi(url, 'put', data, cancelKey)
    },

    updatePlan(data, cancelKey){
        const url = `my-company/plan`

        return this._callApi(url, 'put', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
