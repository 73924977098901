const functions =  {
    availableMethods() {
        return [
            'index',
            'limits',
            'create',
            'update',
            'delete',
            'activate',
            'deactivate',
            'updateLimit'
        ]
    },

    limits(data, cancelKey) {
        const url = `/my-traders/limits`

        return this._callApi(url, 'get', data, cancelKey)
    },

    activate(traderId, cancelKey) {
        const url = `/my-traders/${traderId}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(traderId, cancelKey) {
        const url = `/my-traders/${traderId}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    updateLimit(traderId, productTypeId, data, cancelKey) {
        const url = `/my-traders/${traderId}/limit/${productTypeId}`

        return this._callApi(url, 'put', data, cancelKey)
    },

    resendInvitationEmail(traderId, cancelKey) {
        const url = `/my-traders/${traderId}/resend-invitation-email`

        return this._callApi(url, 'post', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
