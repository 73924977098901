const functions =  {
    availableMethods() {
        return [
            'index',
            'risks',
        ]
    },

    risks(id, cancelKey) {
        const url = `/${this.modelName}/risks`

        return this._callApi(url, 'get', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
