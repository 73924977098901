const functions =  {
    availableMethods() {
        return [
            'create',
            'update',
            'delete',
            'merge',
            'mergeBrokerage',
        ]
    },

    merge(companyId, data, cancelKey) {
        const url = `/${this.modelName}/${companyId}/merge`

        return this._callApi(url, 'post', data, cancelKey)
    },

    mergeBrokerage(companyId, data, cancelKey) {
        const url = `/${this.modelName}/${companyId}/merge-brokerage`

        return this._callApi(url, 'post', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
