const functions = {
    availableMethods() {
        return [
            "all",
            "index",
            "tenors",
            "show",
            "create",
            "update",
            "updateStatus"
        ];
    },

    all(data, cancelKey) {
        const url = `${this.modelName}/all`;

        return this._callApi(url, "get", data, cancelKey);
    },

    tenors(data, cancelKey) {
        const url = `${this.modelName}/tenors`;

        return this._callApi(url, "get", data, cancelKey);
    },

    show(productType, data, cancelKey) {
        const url = `${this.modelName}/${productType}`;

        return this._callApi(url, "get", data, cancelKey);
    },

    create(data, cancelKey) {
        const url = `${this.modelName}`;

        return this._callApi(url, "post", data, cancelKey);
    },

    update(productType, data, cancelKey) {
        const url = `${this.modelName}/${productType}`;

        return this._callApi(url, "put", data, cancelKey);
    },

    updateStatus(productType, data, cancelKey) {
        const url = `${this.modelName}/${productType}/update/status`;

        return this._callApi(url, "put", data, cancelKey);
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject("Please see the docs");
        });
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject("Please see the docs");
        });
    }
};

export default functions;
