const functions =  {
    availableMethods() {
        return [
            'index',
            'create',
            'updadate',
            'delete',
            'activate',
            'deactivate',
        ]
    },

    activate(id, cancelKey) {
        const url = `/${this.modelName}/${id}/activate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    deactivate(id, cancelKey) {
        const url = `/${this.modelName}/${id}/deactivate`

        return this._callApi(url, 'put', {}, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
