import Model from './Model'

let tradingApiRequestToken

const Eloquent = {
    Model,

    setApiRequestToken(token) {
        tradingApiRequestToken = _tradingAxios.interceptors.request.use((config) => {
            config.headers.Authorization = `Bearer ${token || ''}`
            return config
        });
    },

    unsetApiRequestToken() {
        _tradingAxios.interceptors.request.eject(tradingApiRequestToken)
    },

    addResponseInterceptor(handler) {
        _tradingAxios.interceptors.response.use(response => {
            handler(response)

            response.isOk = response.status >= 200 && response.status < 300

            return response
        })
    },

    /**
     * Always assign new _TradingBaseAxios.CancelToken.source()
     * when cancelling Global Key
     */

    cancelAll() {
        _.map(_TradingCancelSourceMap, (value, key) => {
            value.cancel(`Cancelled ${key} request`)
            delete _TradingCancelSourceMap[key]
        })

        _TradingCancelSourceMap[_TradingDefaultCancelKey] = _TradingBaseAxios.CancelToken.source()
    },

    cancelGlobal() {
        _TradingCancelSourceMap[_TradingDefaultCancelKey].cancel('Cancelled all request that uses Global key')
        _TradingCancelSourceMap[_TradingDefaultCancelKey] = _TradingBaseAxios.CancelToken.source()
    },

    cancelGroups(groups=null) {
        if (groups && groups.length) {
            groups.map(key => {
                if(_TradingCancelSourceMap[key]) {
                    _TradingCancelSourceMap[key].cancel(`Cancelled ${key}`)
                    delete _TradingCancelSourceMap[key]
                }
            })
        }
        else {
            const sourceGroups = _.omit(_TradingCancelSourceMap, _TradingDefaultCancelKey)
            _.map(sourceGroups, (value, key) => {
                value.cancel(`Cancelled ${key} request`)
                delete _TradingCancelSourceMap[key]
            })
        }
    },

    _makeClass(modelName, modelFunctions) {
        let NewModel =  class extends this.Model {}

        NewModel.setModelName(`${_.kebabCase(modelName)}`)

        _.each(modelFunctions, (fn, functionName) => {
            NewModel[functionName] = fn
            NewModel.prototype[functionName] = fn
        })

        return NewModel
    }
}

export default Eloquent;
