const functions =  {
    availableMethods() {
        return [
            'index',
            'update'
        ]
    },

    index(data, cancelKey) {
        const url = `counterparties-product-types`

        return this._callApi(url, 'get', data, cancelKey)
    },

    update(counterpartyId, productTypeId, data, cancelKey) {
        const url = `counterparties-product-types/${counterpartyId}/product-type/${productTypeId}`

        return this._callApi(url, 'put', data, cancelKey)
    },

    getFillableAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    },

    getAllAttributes() {
        return new Promise((resolve, reject) => {
            reject('Please see the docs')
        })
    }
}

export default functions
